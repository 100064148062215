import { css } from "@emotion/react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const paobcStyles = (theme: ITheme) => css`
  &.paobc {
    .progress-share-overlay {
      .half-style {
        background: #006c39;
        padding-bottom: 0;
        .second-half {
          .btn-container {
            margin-bottom: 0;
            background: #307746;
            border: none;
            box-shadow: #1e482b 0px 12px 17px -7px;
          }
        }
        .replay-button {
          bottom: -2rem !important;
        }
        .player-rank-board {
          .first-board,
          .middle-board,
          .last-board {
            svg {
              fill: #307746;
            }
            .label-style {
              background: #1e482b;
            }
          }
        }
      }
      .recommended-games-container {
        display: none;
      }
    }
  }
`;

export default paobcStyles;
